import "./App.css";
// import { ThemeProvider } from "@mui/material/styles"; // From old theme
// import { theme } from "./Config/Themes/config"; // From old theme
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./Config/Themes/theme";
import AppContent from "./MainAppComponents/AppContent";
import ToastContainer from "./Common/Toast/ToastContainer";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <>
      <ThemeProvider theme={theme}>
        <AppContent />
      </ThemeProvider>
      <ToastContainer />
    </>
  );
}

export default App;
