import * as CryptoJS from 'crypto-js';
import { encryptionKey } from '../../Config/constant';
import jwtDecode from 'jwt-decode';

export function encrypt(value) {
    return CryptoJS.AES.encrypt(JSON.stringify(value), encryptionKey).toString();
}

export function decrypt(value) {
    return value ? JSON.parse(CryptoJS.AES.decrypt(value, encryptionKey).toString(CryptoJS.enc.Utf8)) : null;
}

export function decodeJwtToken(token) {
    return jwtDecode(token);
}
